import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyRole } from '@app/company/models';
import { Order } from '@app/order/models';
import { AssignType } from '@app/order/routes/assign/state/assign.model';
import { UUID } from '@app/shared/models';

@Component({
  selector: 'tc-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactInfoComponent {
  @Input()
  order?: Order;

  @Input()
  role?: CompanyRole;

  @Input()
  assignEnabled = false;

  @Output()
  public readonly assign = new EventEmitter();

  public readonly assignType = AssignType;

  public get buyerId(): UUID | undefined {
    return this.order?.buyerOrder.companyId;
  }

  public get buyerAccountNumber(): string | undefined {
    return this.order?.supplierOrder.buyerAccountNumber;
  }

  public get buyerContactUserId(): string | undefined {
    return this.order?.buyerOrder.contact?.userId;
  }

  public get supplierId(): UUID | undefined {
    return this.order?.supplierOrder.companyId;
  }

  public get supplierAccountNumber(): string | undefined {
    return this.order?.buyerOrder.supplierAccountNumber;
  }

  public get supplierContactUserId(): string | undefined {
    return this.order?.supplierOrder.contact?.userId;
  }
}
