<tc-table [columns]="columns" [dataSource]="dataSource" matSort matSortActive="createdAt" matSortDirection="desc">

  <ng-container matColumnDef="profilePictureId">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <tc-profile-picture [user]="row.entity" [showRole]="true"></tc-profile-picture>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef class="tc-table-column-main" i18n="@@user.table.name">Name</mat-header-cell>
    <mat-cell *matCellDef="let row" [class.loading-text]="!row.entity" class="tc-table-column-main">
      <tc-user-name [user]="row.entity"></tc-user-name>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="position">
    <mat-header-cell *matHeaderCellDef fxHide.xs class="col-line" i18n="@@user.table.position">Position</mat-header-cell>
    <mat-cell *matCellDef="let row" fxHide.xs [class.loading-text]="!row.entity">
      {{ row.entity?.profile.position }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <mat-header-cell *matHeaderCellDef fxHide.lt-md mat-sort-header arrowPosition="before" [disabled]="true" class="mat-cell--right col-line" i18n="@@user.table.joined">Joined</mat-header-cell>
    <mat-cell *matCellDef="let row" fxHide.lt-md [class.loading-text]="!row.entity" class="mat-cell--right joined">
      <ng-container *ngIf="row.entity?.status === 'active'">{{ row.entity.createdAt | date:'longDate' }}</ng-container>
      <ng-container *ngIf="row.entity?.status === 'initial' || row.entity?.status === 'pending'" i18n="@@user.table.activated">Invited</ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row">
    <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="userMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
      <mat-menu #userMenu="matMenu">
        <mat-list>
          <h4 matSubheader i18n="@@common.mat.actions">Actions</h4>
          <span mat-menu-item
                (click)="reinvite(row.entity.id)"
                [disabled]="row.entity?.status === 'active'"
                i18n="@@user.table.reinvite"
          >Reinvite user</span>
          <span mat-menu-item
                (click)="remove(row.entity.id)"
                [disabled]="!isSuperAdminUser"
                i18n="@@user.table.delete"
          >Delete user</span>
        </mat-list>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: columns;" [routerLink]="row.entity && ['/user', row.entity.id]" [class.tc-table-row-routed]="row.entity"></mat-row>

</tc-table>
