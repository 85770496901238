<mat-form-field appearance="fill" [floatLabel]="floatLabel">
  <mat-label>{{ label }}</mat-label>
  <input matInput type="text" [formControl]="control" [matAutocomplete]="autocomplete">
  <tc-mat-form-field-clear *ngIf="clear" matSuffix></tc-mat-form-field-clear>
  <mat-progress-bar *ngIf="loading" mode="query" class="mat-form-field__progress-bar"></mat-progress-bar>
  <mat-hint *ngIf="hint">
    {{ hint }}
  </mat-hint>
  <mat-error *tcMatFormFieldError i18n="@@shared.demo.error">
    Search and make a selection
  </mat-error>
</mat-form-field>

<mat-autocomplete #autocomplete [displayWith]="displayWith" autoActiveFirstOption>
  <mat-option *ngFor="let entity of options" [value]="entity">
    {{ displayWith(entity) }}
  </mat-option>
  <mat-option *ngIf="options && options.length === 0" disabled i18n="@@common.no.results.found">
    No results found
  </mat-option>
</mat-autocomplete>
