import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';
import { IntegrationCallMethod } from '@app/company/models/enums/integration-settings.enum';
import { EndpointCredentials, FormatType, IntegrationOAuth } from '@app/company/models';
import { MessageMeta } from '@app/shared/models';

export interface IntegrationSettingsView {
  companyId: string;
  orderEventsSettings: OrderEventsSettings;
  orderDocumentsEventsSettings: OrderDocumentsSettings;
  meta: MessageMeta;
}

export interface WebhookCertificate {
  certificate: string;
}

export interface OrderSettings {
  webhookUrl: string;
  method: IntegrationCallMethod;
  authentication: string;
  format: string;
  enabledEventNames: string[];
}

// eslint-disable-next-line
export interface OrderDocumentsSettings extends OrderSettings {}

export interface OrderEventsSettings extends OrderSettings {
  singleDeliveryPerOrderLine: boolean;
  useWhitelisting: boolean;
  whitelistedCompanyIds: string[];
}

interface UpdateIntegrationSettingsView {
  method: IntegrationCallMethod;
  webhookUrl: string;
  authentication: {
    basicAuthCredentials: EndpointCredentials;
    bearerToken: string;
    oAuth: IntegrationOAuth;
  };
  format: FormatType;
  enabledEventNames: string[];
  singleDeliveryPerOrderLine: boolean;
  useWhitelisting: boolean;
  whitelistedCompanyIds: string[];
}

// eslint-disable-next-line
export interface UpdateDocumentIntegrationSettingsView extends UpdateIntegrationSettingsView {}

export interface UpdateOrderIntegrationSettingsView extends UpdateIntegrationSettingsView {}

@Injectable({
  providedIn: 'root',
})
export class OrderWebhookService {
  constructor(private http: HttpClient, private api: API) {}

  public getById$(companyId: string): Observable<IntegrationSettingsView> {
    return this.http.get<IntegrationSettingsView>(this.api.ORDER_WEBHOOK_SETTINGS(companyId));
  }

  public updateOrderEvents$(companyId: string, body: UpdateOrderIntegrationSettingsView): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.ORDER_WEBHOOK_ORDER_EVENTS(companyId), body);
  }

  public disableOrderEvents$(companyId: string): Observable<{ ok: boolean }> {
    return this.http.delete<{ ok: boolean }>(this.api.ORDER_WEBHOOK_ORDER_EVENTS(companyId));
  }

  public updateDocumentsEvents$(
    companyId: string,
    body: UpdateDocumentIntegrationSettingsView,
  ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.ORDER_WEBHOOK_DOCUMENTS_EVENTS(companyId), body);
  }

  public disableDocumentsEvents$(companyId: string): Observable<{ ok: boolean }> {
    return this.http.delete<{ ok: boolean }>(this.api.ORDER_WEBHOOK_DOCUMENTS_EVENTS(companyId));
  }

  public getOrdersCertificateById$(companyId: string): Observable<WebhookCertificate> {
    return this.http.get<WebhookCertificate>(this.api.WEBHOOK_EVENTS_CERTIFICATE(companyId));
  }

  public getDocumentsCertificateById$(companyId: string): Observable<WebhookCertificate> {
    return this.http.get<WebhookCertificate>(this.api.WEBHOOK_DOCUMENTS_CERTIFICATE(companyId));
  }


}
