import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Company, WebhookSettings, WorkflowFullSettings } from '@app/company/models';
import {
  addSciCredentials,
  addSciCredentialsFailure,
  addSciCredentialsSuccess,
  CompanyActionType,
  companyAddMany,
  companyNameUpdate,
  companyUpdate,
  companyUpsert,
  deleteDocumentsWebhookConnector,
  deleteOrderWebhookConnector,
  deleteSciCompanyUrl,
  deleteSciCredentials,
  documentsWebhookConnectorUpdateSuccess, fetchLookerTokenSuccess,
  fetchWebhookConnectorSuccess,
  ordersWebhookConnectorUpdateSuccess,
  overdueSettingsFetchFailure,
  overdueSettingsFetchSuccess,
  overdueSettingsUpdateSuccess,
  sciSettingsFetchSuccess,
  shipmentIntegrationSettingsDelete,
  shipmentIntegrationSettingsFetchSuccess,
  shipmentIntegrationSettingsUpsert,
  shipmentIntegrationSettingsUpsertFailure,
  upsertSciCompanyUrlSuccess,
  workflowSettingsOverdueUpdate,
  workflowSettingsUpdate,
  workflowSettingsUpsert,
} from './company.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';
import { SCIConnector } from '@app/company/core/services/sci-connector.service';
import { ShipmentIntegrationSettings } from '@app/company/core/services/shipment-connector.service';
import { LookerToken } from '@app/analytics/models';
import { AuthTypes } from '@app/company/models/enums/integration-settings.enum';

export interface CompanyState
  extends EntityState<
    Company & WorkflowFullSettings & Partial<SCIConnector> & Omit<ShipmentIntegrationSettings, 'companyId'>
  > {
  readonly pending?: boolean;
  readonly error?: string;
}

export const companyAdapter = createEntityAdapter<
  Company &
    WebhookSettings &
    WorkflowFullSettings &
    Partial<SCIConnector> &
    Partial<LookerToken> &
    Omit<ShipmentIntegrationSettings, 'companyId'>
>({
  selectId: ({
    id,
  }: Company &
    WorkflowFullSettings &
    WebhookSettings &
    Partial<SCIConnector> &
    Partial<LookerToken> &
    Omit<ShipmentIntegrationSettings, 'companyId'>) => {
    return id;
  },
});

export const initialState = companyAdapter.getInitialState();

const companyReducer = createReducer<CompanyState, TypedAction<CompanyActionType>>(
  initialState,
  on(companyUpsert, (state, { entity }) => {
    return companyAdapter.upsertOne(entity, state);
  }),
  on(companyAddMany, (state, { entities }) => {
    return companyAdapter.addMany(entities, state);
  }),
  on(companyUpdate, companyNameUpdate, fetchLookerTokenSuccess, (state, { id, changes }) => {
    return companyAdapter.updateOne({ id, changes }, state);
  }),
  on(workflowSettingsUpsert, (state, { id, changes }) => {
    return companyAdapter.updateOne({ id, changes }, state);
  }),
  on(workflowSettingsUpdate, (state, { id, changes }) => {
    return companyAdapter.updateOne({ id, changes }, state);
  }),

  on(fetchWebhookConnectorSuccess, (state, { id, changes: updated }) => {
        return companyAdapter.updateOne({ id, changes: { webhookConnector: updated } }, state);
  }),
  on(deleteOrderWebhookConnector, (state, { id }) => {
    return companyAdapter.mapOne(
      {
        id,
        map: entity => {
          return {
            ...entity,
            webhookConnector: {
              orderDocumentsEventsSettings: entity.webhookConnector?.orderDocumentsEventsSettings,
            },
          };
        },
      },
      state,
    );
  }),
  on(deleteDocumentsWebhookConnector, (state, { id }) => {
    return companyAdapter.mapOne(
      {
        id,
        map: entity => {
          return {
            ...entity,
            webhookConnector: {
              orderEventsSettings: entity.webhookConnector?.orderEventsSettings,
            },
          };
        },
      },
      state,
    );
  }),
  on(documentsWebhookConnectorUpdateSuccess, (state, { id, changes }) => {
    const authType: AuthTypes = !!changes.authentication.bearerToken && 'bearerToken'
      || !!changes.authentication.basicAuthCredentials && 'basicAuthentication'
      || !!changes.authentication?.oAuth?.clientCertificate && 'oAuthClientCertificate'
      || !!changes.authentication?.oAuth?.clientSecret && 'oAuthClientSecret'
      || 'basicAuthentication';

    return companyAdapter.mapOne(
      {
        id,
        map: entity => {
          return {
            ...entity,
            webhookConnector: {
              orderEventsSettings: entity.webhookConnector?.orderEventsSettings,
              orderDocumentsEventsSettings: {
                enabledEventNames: changes.enabledEventNames,
                format: changes.format,
                webhookUrl: changes.webhookUrl,
                method: changes.method,
                authentication: authType,
              },
            },
          };
        },
      },
      state,
    );
  }),
  on(ordersWebhookConnectorUpdateSuccess, (state, { id, changes }) => {
    const authType: AuthTypes = !!changes.authentication.bearerToken && 'bearerToken'
      || !!changes.authentication.basicAuthCredentials && 'basicAuthentication'
      || !!changes.authentication?.oAuth?.clientCertificate && 'oAuthClientCertificate'
      || !!changes.authentication?.oAuth?.clientSecret && 'oAuthClientSecret'
      || 'basicAuthentication';

    return companyAdapter.mapOne(
      {
        id,
        map: entity => {
          return {
            ...entity,
            webhookConnector: {
              orderDocumentsEventsSettings: entity.webhookConnector?.orderDocumentsEventsSettings,
              orderEventsSettings: {
                enabledEventNames: changes.enabledEventNames,
                webhookUrl: changes.webhookUrl,
                format: changes.format,
                method: changes.method,
                singleDeliveryPerOrderLine: changes.singleDeliveryPerOrderLine,
                whitelistedCompanyIds: changes.whitelistedCompanyIds,
                useWhitelisting: changes.useWhitelisting,
                authentication: authType,
              },
            },
          };
        },
      },
      state,
    );
  }),

  on(workflowSettingsOverdueUpdate, (state, { id, changes: updated }) => {
    return companyAdapter.updateOne({ id, changes: { taskOverdueSettings: updated } }, state);
  }),
  on(overdueSettingsFetchSuccess, overdueSettingsUpdateSuccess, (state, { id, changes: updated }) => {
    return companyAdapter.updateOne({ id, changes: { orderOverdueDelaySettings: updated } }, state);
  }),
  on(overdueSettingsFetchFailure, (state, { id }) => {
    return companyAdapter.updateOne({ id, changes: { orderOverdueDelaySettings: undefined } }, state);
  }),

  on(shipmentIntegrationSettingsFetchSuccess, (state, { id, changes: { companyId, ...needed } }) => {
    return companyAdapter.updateOne({ id, changes: { ...needed } }, state);
  }),
  on(shipmentIntegrationSettingsUpsert, (state, { id, body: changes }) => {
    return companyAdapter.updateOne({ id, changes }, state);
  }),
  on(shipmentIntegrationSettingsUpsertFailure, shipmentIntegrationSettingsDelete, (state, { id }) => {
    return companyAdapter.updateOne(
      {
        id,
        changes: { webhookUrl: undefined, method: undefined, enabledEventNames: [] },
      },
      state,
    );
  }),

  on(sciSettingsFetchSuccess, upsertSciCompanyUrlSuccess, (state, { id, changes }) => {
    return companyAdapter.updateOne({ id, changes }, state);
  }),
  on(deleteSciCompanyUrl, (state, { id }) => {
    return companyAdapter.updateOne({ id, changes: { url: undefined } }, state);
  }),

  on(addSciCredentials, (state, { id, supplierCompanyId, body: { username } }) => {
    const credentials =
      state.entities[id]!.credentials?.filter(cred => {
        return cred.supplierCompanyId !== supplierCompanyId;
      }) || [];
    credentials.push({ supplierCompanyId, username });

    return companyAdapter.updateOne({ id, changes: { credentials: [...credentials] } }, { ...state, pending: true });
  }),
  on(addSciCredentialsSuccess, state => {
    return { ...state, pending: false, error: undefined };
  }),
  on(deleteSciCredentials, (state, { companyId, supplierCompanyId }) => {
    const credentials = state.entities[companyId]!.credentials!.filter(deleted => {
      return deleted.supplierCompanyId !== supplierCompanyId;
    });

    return companyAdapter.updateOne({ id: companyId, changes: { credentials } }, state);
  }),
  on(addSciCredentialsFailure, (state, { id, error, supplierCompanyId }) => {
    const credentials = state.entities[id]!.credentials!.filter(failed => {
      return failed.supplierCompanyId !== supplierCompanyId;
    });

    return companyAdapter.updateOne({ id, changes: { credentials } }, { ...state, pending: false, error });
  }),
);

export function reducer(state = initialState, action: TypedAction<CompanyActionType>): CompanyState {
  return companyReducer(state, action);
}
