import { OrderOverdueThreshold } from './enums/order-overdue-threshold.enum';
import { IntegrationCallMethod } from '@app/company/models/enums/integration-settings.enum';
import { CompanyErpSystem } from '@app/company/models/enums/company-erp-system.enum';
import { TaskOverdueSettings } from '@app/company/routes/settings/components/task-reminder/task-reminder.model';
import { OrderOverdueDelaySettings } from '@app/company/routes/settings/components/task-overdue/task-overdue-delay.model';
import { IntegrationSettingsView } from '@app/company/core/services/order-webhook.service';

export interface UpdateCompany {
  identifiers?: {
    gln?: string;
  };
  profile: CompanyProfileInterface;
  settings: CompanySettingsInterface;
}

export interface CreateCompany {
  name: string;
  roles: CompanyRole[];
  createdAt: Date;
}

export type WorkflowFullSettings = WorkflowSettings & OverdueSettings & Partial<OverdueDelaySettings>;

export interface WorkflowSettings {
  enabledAcknowledgeEventNames?: string[];
}

export interface OverdueSettings {
  taskOverdueSettings?: TaskOverdueSettings;
}

export interface WebhookSettings {
  webhookConnector?: Pick<Partial<IntegrationSettingsView>, 'orderEventsSettings' | 'orderDocumentsEventsSettings'>;
}

export interface OverdueDelaySettings {
  orderOverdueDelaySettings: OrderOverdueDelaySettings;
}

export interface Company extends CreateCompany, UpdateCompany {
  id: string;
}

export interface CompanyProfileInterface {
  logoId?: string;
  location?: string;
  website?: string;
  erpSystem?: CompanyErpSystem;
}

export interface CompanyFormInterface extends CompanyProfileInterface {
  gln?: string;
}

export enum TwoFAEnforcementType {
  ByCompanyAdmin = 'by-company-admin',
  BySuperUser = 'by-super-user',
}

export interface CompanySettingsInterface {
  orderOverdueThreshold?: OrderOverdueThreshold;
  enforce2FA?: TwoFAEnforcementType;
}

export interface CompanyWebhookSettingsInterface {
  integration: CompanyWebhookIntegration;
}

export enum CompanyRole {
  BUYER = 'buyer',
  SUPPLIER = 'supplier',
  ACT_AS_SUPPLIER = 'actAsSupplier',
}

export interface CompanyWebhookIntegration {
  webhook?: {
    orderDocumentsEventEndpoint?: DocumentWebhookIntegrationSettings;
    orderEventEndpoint?: OrderWebhookIntegrationSettings;
  };
  enabledEventNames: string[];
}

export type CompanyIntegrationType = keyof CompanyWebhookIntegration;
export type FormatType = 'JSON' | 'tXML';

export interface OrderWebhookIntegrationSettings {
  webhookUrl?: string;
  authentication: {
    oAuth: IntegrationOAuth;
    basicAuthCredentials?: EndpointCredentials;
    bearerToken?: string | null;
  }
  method: IntegrationCallMethod;
  singleDeliveryPerOrderLine: boolean;
  useWhitelisting: boolean;
  whitelistedCompanyIds: string[];
  format?: FormatType;
}

export interface DocumentWebhookIntegrationSettings {
  webhookUrl?: string;
  method: IntegrationCallMethod;
  authentication: {
    basicAuthCredentials?: EndpointCredentials;
    oAuth?: IntegrationOAuth;
    bearerToken?: string | null;
  };
  format?: FormatType;
}

export interface EndpointCredentials {
  username: string | null;
  password: string | null;
}

export interface CompanyWorkflowSettings extends OverdueSettings {
  enabledAcknowledgeEventNames: string[];
  companyId: string;
}

export interface IntegrationOAuth {
  accessTokenUrl: string | null;
  clientId: string | null;
  clientSecret?: string | null;
  clientCertificate?: boolean | null;
  scope?: string | null;
}
