import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSocketService } from '@app/core/services/web-socket/web-socket.service';
import { paramsFromObject } from '@app/util/http';
import {
  Acceptance,
  AttachDocument,
  ChangeItemDetails,
  LineRemoveLabel,
  LineRequest,
  LineUpdateLabels,
  Order,
  OrderAcceptance,
  OrderApprove,
  OrderProposal,
  OrderRejection,
  OrderRemoveLabel,
  OrderReopen,
  OrderResend,
  OrderUpdateLabels,
  OrderUpdateLogistics,
  Rejection,
  RequestApproval,
  RequestRejection,
  UpdateLogistics,
} from '@app/order/models';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';
import { TypedAction } from '@ngrx/store/src/models';
import { OrderActionType } from '@app/order/core/state/order.actions';
import { AssignRequest } from '@app/order/routes/assign/state/assign.model';
import { CompanyRole } from '@app/company/models';
import { OrderReconfirm } from '../../models/order.model';

@Injectable()
export class OrderService {
  constructor(private http: HttpClient, private webSocketService: WebSocketService, private api: API) {}

  public websocket$(
    companyId: string,
    action: () => TypedAction<OrderActionType.WEBSOCKET_CONNECTED>,
  ): Observable<Order> {
    const params = paramsFromObject({ companyId });

    return this.webSocketService.createWebSocket$<Order>(this.api.ORDER_WS(), params, action);
  }

  public acceptOrder$({ orderId, body }: OrderAcceptance): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_ACCEPT(encodedOrderId), body);
  }

  public reconfirmOrderLines$({ orderId, body }: OrderReconfirm ): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_RECONFIRM(encodedOrderId), body);
  }

  public resendOrderByBuyer$({ orderId, body }: OrderResend): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_RESEND_BUYER(encodedOrderId), body);
  }

  public removeOrderLabelBySupplier$({ orderId, body }: OrderRemoveLabel): Observable<any> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.delete(this.api.ORDER_SUPPLIER_LABELS(encodedOrderId), { body });
  }

  public removeOrderLabelsByBuyer$({ orderId, body }: OrderRemoveLabel): Observable<any> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.delete<any>(this.api.ORDER_BUYER_LABELS(encodedOrderId), { body });
  }

  public updateOrderLabelsBySupplier$({ orderId, body }: OrderUpdateLabels): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_SUPPLIER_LABELS(encodedOrderId), body);
  }

  public updateOrderLabelsByBuyer$({ orderId, body }: OrderUpdateLabels): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_BUYER_LABELS(encodedOrderId), body);
  }

  public removeLineLabelBySupplier$({ orderId, body, linePosition }: LineRemoveLabel): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.delete<{ ok: boolean }>(this.api.ORDER_LINE_SUPPLIER_LABELS(encodedOrderId, encodedLinePosition), {
      body,
    });
  }

  public removeLineLabelByBuyer({ orderId, body, linePosition }: LineRemoveLabel): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.delete<{ ok: boolean }>(this.api.ORDER_LINE_BUYER_LABELS(encodedOrderId, encodedLinePosition), {
      body,
    });
  }

  public updateLineLabelsBySupplier$({ orderId, body, linePosition }: LineUpdateLabels): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(
      this.api.ORDER_LINE_SUPPLIER_LABELS(encodedOrderId, encodedLinePosition),
      body,
    );
  }

  public updateLineLabelsByBuyer({ orderId, body, linePosition }: LineUpdateLabels): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_LINE_BUYER_LABELS(encodedOrderId, encodedLinePosition), body);
  }

  public resendOrderBySupplier$({ orderId, body }: OrderResend): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_RESEND_SUPPLIER(encodedOrderId), body);
  }

  public updateLogisticOrder$({ orderId, body }: OrderUpdateLogistics): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_UPDATE_LOGISTIC(encodedOrderId), body);
  }

  public acceptLine$({ orderId, linePosition, body }: Acceptance): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_WITH_LINE_ACCEPT(encodedOrderId, encodedLinePosition), body);
  }

  public rejectLine$({ orderId, linePosition, body }: Rejection): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_WITH_LINE_REJECT(encodedOrderId, encodedLinePosition), body);
  }

  public rejectIssuedOrder$({ orderId, body }: OrderRejection): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_REJECT(encodedOrderId), body);
  }

  public rejectOrder$({ orderId, body, role }: OrderRejection): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    const api =
      role === CompanyRole.BUYER
        ? this.api.ORDER_REQUESTS_REJECT_AS_BUYER(encodedOrderId)
        : this.api.ORDER_REQUESTS_REJECT_AS_SUPPLIER(encodedOrderId);

    return this.http.post<{ ok: boolean }>(api, body);
  }

  public approveOrder$({ orderId, body, role }: OrderApprove): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const api =
      role === CompanyRole.BUYER
        ? this.api.ORDER_REQUESTS_APPROVE_AS_BUYER(encodedOrderId)
        : this.api.ORDER_REQUESTS_APPROVE_AS_SUPPLIER(encodedOrderId);

    return this.http.post<{ ok: boolean }>(api, body);
  }

  public proposeOrderChanges$({ orderId, body }: OrderProposal): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_PROPOSE(encodedOrderId), body);
  }

  public reopenOrderChanges$({ orderId, body }: OrderReopen): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_REOPEN(encodedOrderId), body);
  }

  public proposeLineChanges$({ orderId, linePosition, body }: LineRequest): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_WITH_LINE_PROPOSE(encodedOrderId, encodedLinePosition), body);
  }

  public reopenLineRequest$({ orderId, linePosition, body }: LineRequest): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_WITH_LINE_REOPEN(encodedOrderId, encodedLinePosition), body);
  }

  public approveLineReopen$({ orderId, linePosition, body, role }: RequestApproval): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    const api =
      role === CompanyRole.BUYER
        ? this.api.ORDER_WITH_LINE_REOPEN_APPROVE_AS_BUYER(encodedOrderId, encodedLinePosition)
        : this.api.ORDER_WITH_LINE_REOPEN_APPROVE_AS_SUPPLIER(encodedOrderId, encodedLinePosition);

    return this.http.post<{ ok: boolean }>(api, body);
  }

  public rejectLineReopenBySupplier$({ orderId, linePosition, body }: RequestRejection): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(
      this.api.ORDER_WITH_LINE_REOPEN_REJECT_AS_SUPPLIER(encodedOrderId, encodedLinePosition),
      body,
    );
  }

  public rejectLineReopen$({ orderId, linePosition, body, role }: RequestRejection): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    const api =
      role === CompanyRole.BUYER
        ? this.api.ORDER_WITH_LINE_REOPEN_REJECT_AS_BUYER(encodedOrderId, encodedLinePosition)
        : this.api.ORDER_WITH_LINE_REOPEN_REJECT_AS_SUPPLIER(encodedOrderId, encodedLinePosition);

    return this.http.post<{ ok: boolean }>(api, body);
  }

  public approveLineProposal$({ orderId, linePosition, body }: RequestApproval): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(
      this.api.ORDER_WITH_LINE_PROPOSAL_ACCEPT(encodedOrderId, encodedLinePosition),
      body,
    );
  }

  public rejectLineProposal$({ orderId, linePosition, body }: RequestRejection): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(
      this.api.ORDER_WITH_LINE_PROPOSAL_REJECT(encodedOrderId, encodedLinePosition),
      body,
    );
  }

  public updateLogistics$({ orderId, linePosition, body }: UpdateLogistics): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(
      this.api.ORDER_WITH_LINE_UPDATE_LOGISTIC(encodedOrderId, encodedLinePosition),
      body,
    );
  }

  public changeItemDetails$({ orderId, linePosition, body }: ChangeItemDetails): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedLinePosition = encodeURIComponent(linePosition);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_ITEM_DETAILS(encodedOrderId, encodedLinePosition), body);
  }

  public attachOrderDocument$(orderId: string, body: AttachDocument): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_DOCUMENT(encodedOrderId), body);
  }

  public attachOrderLineDocument$(
    orderId: string,
    orderLineId: string,
    body: AttachDocument,
  ): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);
    const encodedOrderLineId = encodeURIComponent(orderLineId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_LINE_DOCUMENT(encodedOrderId, encodedOrderLineId), body);
  }

  public assignBuyerOrder$({ orderId, body }: AssignRequest): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_BUYER_ASSIGN(encodedOrderId), body);
  }

  public assignSupplierOrder$({ orderId, body }: AssignRequest): Observable<{ ok: boolean }> {
    const encodedOrderId = encodeURIComponent(orderId);

    return this.http.post<{ ok: boolean }>(this.api.ORDER_SUPPLIER_ASSIGN(encodedOrderId), body);
  }
}
