import {
  BuyerDelivery,
  ChargeLine,
  OrderLine,
  OrderLineRequest,
  OrderLineRequests,
  OrderLineSearchView,
  Price,
  Prices,
} from '@app/order/models';
import { getProperty } from '@app/shared/pipes/unique.pipe';

export const hasReopenRequest = (orderLine: OrderLine) => {
  const buyerReopen = getProperty<OrderLineRequest>('requests.reopenRequest', orderLine!.buyerLine) || {};
  const supplierReopen = getProperty<OrderLineRequests>('requests.reopenRequest', orderLine!.supplierLine) || {};

  return Object.keys(buyerReopen).length > 0 || Object.keys(supplierReopen).length > 0;
};
export const hasOpenBuyerReopenRequest = (orderLine: OrderLine) => {
  return orderLine?.buyerLine.requests?.reopenRequest?.status === 'Open';
};
export const hasOpenBuyerReconfirmationRequest = (orderLine: OrderLine) => {
  return orderLine?.buyerLine.requests?.reconfirmationRequest?.status === 'Open';
};
export const hasBuyerReopenRequestChargeLines = (orderLine: OrderLine) => {
  return !!getProperty('buyerLine.requests.reopenRequest.chargeLines', orderLine);
};

export const hasOpenSupplierReopenRequest = (orderLine: OrderLine) => {
  return orderLine?.supplierLine.requests?.reopenRequest?.status === 'Open';
};
export const hasSupplierReopenRequestChargeLines = (orderLine: OrderLine) => {
  return !!getProperty('supplierLine.requests.reopenRequest.chargeLines', orderLine);
};

export const hasOpenSupplierProposal = (orderLine: OrderLine) => {
  return orderLine?.supplierLine.requests?.proposal?.status === 'Open';
};
export const hasSupplierOpenProposalChargeLines = (orderLine: OrderLine) => {
  return !!getProperty('supplierLine.requests.proposal.chargeLines', orderLine);
};

export function confirmedSchedule(line?: OrderLineSearchView): BuyerDelivery[] | undefined {
  return line?.confirmedLine?.deliverySchedule;
}

export function buyerSchedule(line?: OrderLineSearchView): BuyerDelivery[] | undefined {
  return line?.buyerLine.deliverySchedule;
}

export function currentSchedule(line?: OrderLineSearchView): BuyerDelivery[] | undefined {
  return confirmedSchedule(line) || buyerSchedule(line);
}

export function confirmedPrices(line?: OrderLineSearchView): Prices | undefined {
  return line?.confirmedLine?.prices;
}

export function buyerPrices(line?: OrderLineSearchView): Prices | undefined {
  return line?.buyerLine.prices;
}

export function currentPrices(line?: OrderLineSearchView): Prices | undefined {
  return confirmedPrices(line) || buyerPrices(line);
}

export function currentPrice(line?: OrderLineSearchView): Price | undefined {
  return currentPrices(line) && singlePrice(currentPrices(line)!);
}

export function priceUnitOfMeasureIsoEquals(left?: string, right?: string): boolean {
  const equalUnits = [
    ['', '-', 'na', 'unknown'],
    ['bg', 'bag', 'zak'],
    ['c62', 'ea', 'each', 'one', 'pc', 'pce', 'pcs', 'piece', 'st', 'stuks'],
    ['cmt', 'cm'],
    ['cr', 'crate', 'krat'],
    ['cs', 'case'],
    ['ct', 'carton', 'karton'],
    ['d97', 'pallet'],
    ['grm', 'gram'],
    ['kg', 'kgm', 'kilogram'],
    ['l', 'ltr', 'litre'],
    ['m', 'mtr', 'metre', 'meter'],
    ['m2', 'mkt'],
    ['ro', 'roll', 'rol'],
    ['pk', 'package', 'pakket', 'doos'],
    ['pr', 'pair', 'paar'],
    ['set'],
    ['st', 'sheet', 'vel'],
  ];

  const leftLowerCase = left?.toLowerCase();
  const rightLowerCase = right?.toLowerCase();

  if (leftLowerCase === rightLowerCase) {
    return true;
  } else if (leftLowerCase === undefined || rightLowerCase === undefined) {
    return false;
  } else {
    const arrayWithSimilarUnits = equalUnits.find(listOfEqualUnits => {
      return listOfEqualUnits.includes(leftLowerCase);
    });

    return !!arrayWithSimilarUnits && arrayWithSimilarUnits.includes(rightLowerCase);
  }
}

export function singlePrice(prices: Prices): Price | undefined {
  if (prices.netPrice) {
    return prices.netPrice;
  }

  if (typeof prices.discountPercentage !== 'number' && prices.grossPrice) {
    return prices.grossPrice;
  }

  if (typeof prices.discountPercentage === 'number' && prices.grossPrice) {
    const value = prices.grossPrice.priceInTransactionCurrency.value * (-prices.discountPercentage / 100 + 1);

    return {
      ...prices.grossPrice,
      priceInTransactionCurrency: {
        ...prices.grossPrice.priceInTransactionCurrency,
        value,
      },
    };
  }
}

export function requestedChargeLines(orderLine: OrderLineSearchView): ChargeLine[] {
  if (hasOpenBuyerReopenRequest(orderLine) && hasBuyerReopenRequestChargeLines(orderLine)) {
    return orderLine.buyerLine.requests!.reopenRequest!.chargeLines;
  }

  if (hasOpenSupplierReopenRequest(orderLine) && hasSupplierReopenRequestChargeLines(orderLine)) {
    return orderLine.supplierLine.requests.reopenRequest!.chargeLines;
  }

  if (hasOpenSupplierProposal(orderLine) && hasSupplierOpenProposalChargeLines(orderLine)) {
    return orderLine.supplierLine.requests.proposal!.chargeLines;
  }

  return orderLine?.confirmedLine?.chargeLines || orderLine?.buyerLine.chargeLines || [];
}

export function changePanelSort(path: string, step: number, config: Record<string, number>): Record<string, number> {
  if (step === 0) {
    return config;
  }

  const panelsList = Object.keys(config)
    .map(key => {
      return { key, value: config[key] };
    })
    .sort((a, b) => {
      return a.value - b.value;
    });

  const findIndex = panelsList.findIndex(el => {
    return el.key === path;
  });

  const element = panelsList[findIndex];

  let shiftedPanels = [];

  if (step > 0) {
    const beginPart = panelsList.slice(0, findIndex) || [];
    const shiftedPart = panelsList.slice(findIndex + 1, findIndex + 1 + step).map(el => {
      return {
        key: el.key,
        value: el.value - 1,
      };
    });
    const endPart = panelsList.slice(findIndex + 1 + step, panelsList.length) || [];

    shiftedPanels = [...beginPart, ...shiftedPart, { key: path, value: element.value + step }, ...endPart];
  } else {
    const beginPart = panelsList.slice(0, findIndex + step) || [];
    const shiftedPart = panelsList.slice(findIndex + step, findIndex).map(el => {
      return {
        key: el.key,
        value: el.value + 1,
      };
    });
    const endPart = panelsList.slice(findIndex + 1, panelsList.length) || [];

    shiftedPanels = [...beginPart, ...shiftedPart, { key: path, value: element.value + step }, ...endPart];
  }

  const shiftedConfig = shiftedPanels.reduce((acc: Record<string, number>, el) => {
    acc[el.key] = Number(el.value);

    return acc;
  }, {});

  return shiftedConfig;
}
