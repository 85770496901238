import { NgModule } from '@angular/core';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { HeaderComponent } from './components/header/header.component';
import { RoleDirective } from './directives/role.directive';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { TitleComponent } from './components/title/title.component';
import { DisableControlByRoleDirective } from '@app/auth/shared/directives/disable-control-by-role.directive';
import { CompanyLimitationDirective } from './directives/company-limitation.directive';

const components = [HeaderComponent, TitleComponent];
const directives = [RoleDirective, FeatureToggleDirective, DisableControlByRoleDirective, CompanyLimitationDirective];

@NgModule({
  imports: [RootSharedModule],
  declarations: [components, directives],
  exports: [components, directives],
})
export class AuthSharedModule {}
