import { Environment } from './environment.interface';

export const environment: Environment = {
  environment: 'accp',
  intercomAppId: 'nel1ptj2',
  production: true,
  rollbarAccessToken: 'e11c7f9a600e45ecb8a6fbe0789c1016',
  mixpanelToken: '6cf351141627da47e27455d1306c503c',
  ipBaseGeoKey: 'GqQQrDQJStM7UwEphJccOuCEmj6Blx1aiwWFrUVX',
  mixpanelId: '1381845',
  retryConnectionFailures: true,
  azure: {
    domainMappings: {
      'damen.com': 'damen',
      'elcee.ch': 'elcee',
      'elcee.com': 'elcee',
      'elcee.de': 'elcee',
      'elcee.dk': 'elcee',
      'elcee.nl': 'elcee',
      'elcee.se': 'elcee',
      'wassenburgmedical.nl': 'wassenburg',
      'tradecloud1accp.onmicrosoft.com': 'tradecloud',
    },
    app: {
      damen: {
        clientId: '3270b5ac-db7c-4fd8-a89c-8103e9ac206a',
        tenantId: 'ddc22e8a-9100-4f41-8be4-472d728426c5',
      },
      elcee: {
        clientId: '8ca631c8-3d65-4a4e-8af8-66a3dcd4347d',
        tenantId: 'e55db25b-50a2-4622-9a7f-7e7a65ec221e',
      },
      wassenburg: {
        clientId: '1303323a-5480-4e80-8896-182b90871ab5',
        tenantId: '1c686183-49b4-4cf3-906d-f7b4edfe2a32',
      },
      tradecloud: {
        clientId: 'f1c10427-5970-4b94-952e-e616eda05e68',
        tenantId: '1233ff42-e8e4-4c71-b37d-43fb90847ee8',
      },
    },
  },
};
